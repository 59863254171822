import React from "react"

const Youtube = () => {
  return (
    <svg
      width="78"
      height="54"
      viewBox="0 0 78 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.3721 8.43535C75.9281 6.80192 75.0575 5.31233 73.8475 4.11566C72.6375 2.91899 71.1306 2.0572 69.4775 1.61656C63.3924 3.28015e-07 39 0 39 0C39 0 14.6076 -3.28015e-07 8.52254 1.60796C6.8687 2.04718 5.36097 2.90848 4.15076 4.10537C2.94056 5.30227 2.07048 6.79262 1.6279 8.42675C-3.32083e-07 14.4459 0 27 0 27C0 27 -3.32083e-07 39.5541 1.6279 45.5647C2.52455 48.8838 5.17098 51.4978 8.52254 52.3834C14.6076 54 39 54 39 54C39 54 63.3924 54 69.4775 52.3834C72.8377 51.4978 75.4754 48.8838 76.3721 45.5647C78 39.5541 78 27 78 27C78 27 78 14.4459 76.3721 8.43535ZM31.2522 38.5223V15.4777L51.4487 26.914L31.2522 38.5223Z"
        fill="black"
      />
    </svg>
  )
}

export default Youtube
